<template>
  <div class="d-flex h-auto flex-column">
    <v-card-title>
      <span class="text-subtitle"> {{ $t("main.form.formDetail") }} </span>
    </v-card-title>
    <v-card-text class="mb-0 pb-0">
      <v-row>
        <!-- Form name -->
        <v-col cols="4">
          <v-text-field
            :label="$t('main.form.formName')"
            v-model="formDetail.code"
            dense
            outlined
          ></v-text-field>
        </v-col>
        <!-- Form description -->
        <v-col cols="8">
          <v-textarea
            v-model="formDetail.description"
            color="teal"
            dense
            outlined
            rows="1"
          >
            <template v-slot:label>
              <div>
                {{ $t("main.form.form") }}
                <small>{{ $t("main.form.description_1") }}</small>
              </div>
            </template>
          </v-textarea>
        </v-col>
      </v-row>
    </v-card-text>
    <!-- Drag drop layout -->
    <v-card-title class="mt-0 pt-0">
      <span class="text-subtitle"> {{ $t("main.form.layout") }} </span>
    </v-card-title>
    <v-card-text
      class="layout_container examination-form"
      :class="[{ dragenter_highlight: isDragEnter }]"
      @drop="onDropComponent"
      @dragover.prevent
      @dragenter.prevent
      @dragenter="isDragEnter = true"
      @dragleave="isDragEnter = false"
    >
      <v-container class="empty" v-if="!formDetail.components.length">
        <span>{{ $t("main.form.positionForm") }}</span>
      </v-container>
      <!-- List Component -->
      <draggable v-model="formDetail.components" v-bind="dragOptions">
        <transition-group type="transition" name="flip-list">
          <template v-for="(item, i) in formDetail.components">
            <ComponentCreate :key="i">
              <component v-bind:is="item" :id="item"></component>
              <RemoveComponent
                class="removeIcon"
                @click="removeFormComponent(item)"
              >
                <svg width="16" height="16" stroke="#595959">
                  <path d="M 4 4 L 12 12 M 12 4 L 4 12"></path>
                </svg>
              </RemoveComponent>
            </ComponentCreate>
          </template>
        </transition-group>
      </draggable>
    </v-card-text>
    <v-card-actions class="justify-center pt-5 pb-5">
      <!-- Save form -->
      <v-btn
        color="blue darken-1 white--text"
        class="pl-5 pr-5"
        @click="saveFormDetail"
      >
        {{ $t("main.form.saveForm") }}
      </v-btn>
    </v-card-actions>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import _ from "lodash";
import ManageFormService from "@/components/examination/management/manageForm";
import i18n from "@/plugins/i18n";

import { ComponentCreate, RemoveComponent } from "./style";

export default {
  components: {
    draggable,
    ComponentCreate,
    RemoveComponent,
  },
  props: {
    formID: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      formDetail: {
        code: "",
        description: "",
        components: [],
      },
      dragOptions: {
        animation: 200,
      },
      isDragEnter: false,
    };
  },
  created() {
    this.renderFormDetail();
  },
  methods: {
    showError(message) {
      this.$toast.error(message);
    },
    showSuccess(message) {
      this.$toast.success(message);
    },
    onDropComponent(e) {
      var componentName;
      try {
        componentName = JSON.parse(e.dataTransfer.getData("componentName"));
      } catch {
        return;
      }
      if (!componentName) return;
      this.formDetail.components.push(componentName);
      this.isDragEnter = false;
    },
    async renderFormDetail() {
      if (!this.formID) return;
      var result = await ManageFormService.getForm(this.formID);
      if (!result || result.error) {
        this.showError("Cannot get form detail! Please try again later.");
        return;
      }
      var { code, description, components } = result;
      this.formDetail = {
        code,
        description,
        components: components.split(","),
      };
    },
    async saveFormDetail() {
      if (_.isEmpty(this.formDetail.components)) {
        this.showError("You should import components!");
        return;
      }
      if (!this.formDetail.code || !this.formDetail.description) {
        this.showError("Name and description should not be empty!");
        return;
      }
      // if (formID) ==> update
      var body = {
        code: this.formDetail.code,
        description: this.formDetail.description,
        components: this.formDetail.components.join(","),
      };
      var result;
      if (this.formID) {
        result = await ManageFormService.updateForm(this.formID, body);
      } else {
        result = await ManageFormService.createForm(body);
      }
      if (!result || result.error) {
        this.showError("Cannot create or update form! Please try again later.");
        return;
      }
      this.showSuccess(i18n.t("message.formDetail"));
    },
    removeFormComponent(nameComponent) {
      this.formDetail.components = this.formDetail.components.filter(
        (item) => item != nameComponent
      );
    },
  },
};
</script>

<style scoped lang="scss">
.layout_container {
  width: calc(100% - 36px);
  display: inline-block;
  min-height: 120px;
  border: 2px dashed rgba(0, 0, 0, 0.6);
  margin: 0px 18px 0px 18px;

  .empty {
    min-height: 120px;

    span {
      font-size: 110%;
      width: 100%;
      line-height: 120px;
      display: inline-block;
      text-align: center;
    }
  }
}
</style>