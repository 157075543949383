<template>
  <v-row>
    <v-col cols="6">
      <FormDetail :formID="formID" />
    </v-col>
    <v-col cols="6">
      <ComponentsList />
    </v-col>
  </v-row>
</template>

<script>
import FormDetail from "@/components/examination/management/FormDetail";
import ComponentsList from "@/components/examination/management/ComponentsList";

export default {
  components: {
    FormDetail,
    ComponentsList,
  },
  data() {
    return {};
  },
  computed: {
    formID() {
      var formID = this.$route.params.formID;
      if (formID) return parseInt(formID);
      return null;
    },
  },
};
</script>
