<template>
  <div class="d-flex h-auto flex-column examination-form">
    <v-card-title>
      <span class="text-subtitle">{{ $t("main.form.listComponent") }}</span>
    </v-card-title>
    <v-card-text>
      <div class="d-block w-100 mt-2 mb-2">
        <v-text-field
          append-icon="mdi-database-search"
          :label="$t('main.form.searchComponent')"
          v-model="keySearch"
          outlined
          dense
        ></v-text-field>
      </div>
      <v-expansion-panels class="auto-scroll">
        <v-expansion-panel v-for="(item, i) in listComponents" :key="i">
          <v-expansion-panel-header
            draggable
            @dragstart="moveStart($event, item)"
          >
            {{ i + 1 }}.
            <span class="ml-2">{{
              getComponentMetaData(item).description ||
              getComponentMetaData(item).name ||
              "N/A"
            }}</span>
            {{ item }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <component v-bind:is="item"></component>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card-text>
  </div>
</template>

<script>
import _ from "lodash";
import { transliterateChar } from "@/components/examination/utils";

export default {
  name: "ListExistComponent",
  components: {},
  data() {
    return {
      keySearch: "",
      listComponents: [],
    };
  },
  created() {
    this.listComponents = this.EhrComponents;
  },
  watch: {
    keySearch() {
      this.search();
    },
  },
  methods: {
    getComponentMetaData(compName) {
      var archeTypeID = compName.replace("Component", "");
      return (
        Object.values(this.Archetypes).find(
          (a) => a.archetypeID.toLowerCase() == archeTypeID.toLowerCase()
        ) || {}
      );
    },
    moveStart(evt, item) {
      evt.dataTransfer.dropEffect = "move";
      evt.dataTransfer.effectAllowed = "move";
      evt.dataTransfer.setData("componentName", JSON.stringify(item));
    },
    search() {
      if (_.isEmpty(this.keySearch)) {
        this.listComponents = this.EhrComponents;
        return;
      }
      var keySearch = transliterateChar(this.keySearch).toLowerCase();
      this.listComponents = this.EhrComponents.filter((item) => {
        var metaData = this.getComponentMetaData(item);
        var { archetypeID, description, name } = metaData;
        archetypeID = archetypeID || "";
        description = description || "";
        name = name || "";
        var idComponent = transliterateChar(archetypeID).toLowerCase();
        var nameComponent = transliterateChar(name).toLowerCase();
        var descriptionComponent = transliterateChar(
          description || ""
        ).toLowerCase();
        if (
          nameComponent.includes(keySearch) ||
          descriptionComponent.includes(keySearch) ||
          idComponent.includes(keySearch)
        ) {
          return item;
        }
      });
    },
  },
};
</script>

<style scoped>
.content__component:hover {
  background-color: #eaeaea;
  cursor: pointer;
}
.auto-scroll {
  overflow: auto;
  max-height: calc(100vh - 160px);
  padding: 10px;
}
</style>