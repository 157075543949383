import styled from 'vue-styled-components';

export const ComponentCreate = styled.div`
    display: block;
    position: relative;
    padding: 10px;
    z-index: 1;
    cursor: pointer;
    border: 0.5px solid #ffffff;

    .removeIcon {
        visibility: hidden;   
    }
    
    .moveUpIcon {
        visibility: hidden;
    }

    .moveDownIcon {
        visibility: hidden
    }

    &:hover {
        border: 0.5px solid  #dee1e6;
        .removeIcon {
            visibility: visible;
            background-color: #dee1e6;
        }
        .moveUpIcon , .moveDownIcon {
            visibility: visible;
        }
    }
`;

export const RemoveComponent = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;
    height: auto;
    width: auto;
    padding: 1px;
    border-radius: 50%;

    position: absolute;
    top: -8px;
    right: -8px;
    z-index: 999;

    &:hover {
        color: red;
        background-color: #dee1e6;
    }
`;

export const BoxIconUpDown = styled.div`

`;

export const MoveUp = RemoveComponent.extend`
    top: -8px;
    right: 18px;
`;

export const MoveDown = RemoveComponent.extend`
    top: -8px;
    right: 42px;
`;