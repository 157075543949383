import _ from 'lodash';

const PrefixName = 'Component';

export const returnPath = (selfComponent) => {
  var listPathValue = [];

  _.forEach(selfComponent.primaryValue, (item) => {

    if (typeof selfComponent[`${item}`] != 'undefined') {
      let record = {
        archetypeID: `${selfComponent.rootPath}`,
        path: `${selfComponent.rootPath}${selfComponent[`${item}`].path}`,
        value: selfComponent[`${item}`].value
      }
      listPathValue.push(record);
    } else {
      let record = accessNestObjectPathValue(selfComponent, item, selfComponent);
      listPathValue.push(record);
    }
  });
  return listPathValue;
}

export const returnNameComponent = (listComponent) => {
  return listComponent.map(item => `${item}${PrefixName}`);
}

export const findComponentName = (listPath) => {
  listPath = listPath.map(item => {
    let arrayPath = item.split('/');
    let nameComponent = arrayPath[0];
    return nameComponent;
  })
  return _.uniq(listPath);
}

export const nameComponent = (selfComponent) => {
  return `${selfComponent.rootPath}${PrefixName}`;
}

export const parseTextDataToComponent = (selfComponent) => {
  selfComponent.primaryValue.forEach(item => {
    let findDataPoint = selfComponent.dataPoint.find(itemVal => itemVal.path.includes(selfComponent[item].path));
    if (findDataPoint != null) {
      selfComponent[item].value = findDataPoint.value;
    }
  });
  return selfComponent;
}

/**
 * Convert a vietnamese characters into alphabet characters to compare easily
 * @param {*} input 
 * @return string alphabet
 */
export const transliterateChar = (input) => {
  var mappings = {
    "ĂÂÀẰẦẢẲẨÃẴẪÁẮẤẠẶẬ": "A",
    "ÊÈỀẺỂẼỄÉẾẸỆ": "E",
    "ÌỈĨÍỊ": "I",
    "ÔƠÒỒỜỎỔỞÕỖỠÓỐỚỌỘỢ": "O",
    "ƯÙỪỦỬŨỮÚỨỤỰ": "U",
    "ỲỶỸỴ": "Y",
    "ăâàằầảẳẩãẵẫáắấạặậ": "a",
    "êèềẻểẽễéếẹệ": "e",
    "ìỉĩíị": "i",
    "ôơòồờỏổởõỗỡóốớọộợ": "o",
    "ưùừủửũữúứụự": "u",
    "ỳỷỹỵ": "y"
  };
  for (const c of input.split(""))
    for (const mapping in mappings)
      if (mapping.includes(c))
        input = input.replaceAll(c, mappings[mapping]);
  return input;
}

function accessNestObjectPathValue(object, propsString, self) {
  if (typeof propsString != 'string')
    return;
  return {
    archetypeID: self.rootPath,
    path: _.get(object, propsString).path,
    value: _.get(object, propsString).value
  }
}